import gsap from 'gsap';
import '../page/current.js';
import '../page/future.js';

export default class Main {
	constructor() {
		this.emojis = [
			'😄',
			'😃',
			'😀',
			'😊',
			'☺',
			'😉',
			'😍',
			'😘',
			'😚',
			'😗',
			'😙',
			'😜',
			'😝',
			'😛',
			'😳',
			'😁',
			'😔',
			'😌',
			'😒',
			'😞',
			'😣',
			'😢',
			'😂',
			'😭',
			'😪',
			'😥',
			'😰',
			'😅',
			'😓',
			'😩',
			'😫',
			'😨',
			'😱',
			'😠',
			'😡',
			'😤',
			'😖',
			'😆',
			'😋',
			'😷',
			'😎',
			'😴',
			'😵',
			'😲',
			'😟',
			'😦',
			'😧',
			'😈',
			'👿',
			'😮',
			'😬',
			'😐',
			'😕',
			'😯',
			'😶',
			'😇',
			'😏',
			'😑',
			'👲',
			'👳',
			'👮',
			'👷',
			'💂',
			'👶',
			'👦',
			'👧',
			'👨',
			'👩',
			'👴',
			'👵',
			'👱',
			'👼',
			'👸',
			'😺',
			'😸',
			'😻',
			'😽',
			'😼',
			'🙀',
			'😿',
			'😹',
			'😾',
			'👹',
			'👺',
			'🙈',
			'🙉',
			'🙊',
			'💀',
			'👽',
			'💩',
			'🔥',
			'✨',
			'🌟',
			'💫',
			'💥',
			'💢',
			'💦',
			'💧',
			'💤',
			'💨',
			'👂',
			'👀',
			'👃',
			'👅',
			'👄',
			'👍',
			'👎',
			'👌',
			'👊',
			'✊',
			'✌',
			'👋',
			'✋',
			'👐',
			'👆',
			'👇',
			'👉',
			'👈',
			'🙌',
			'🙏',
			'☝',
			'👏',
			'💪',
			'🚶',
			'🏃',
			'💃',
			'👫',
			'👪',
			'👬',
			'👭',
			'💏',
			'💑',
			'👯',
			'🙆',
			'🙅',
			'💁',
			'🙋',
			'💆',
			'💇',
			'💅',
			'👰',
			'🙎',
			'🙍',
			'🙇',
			'🎩',
			'👑',
			'👒',
			'👟',
			'👞',
			'👡',
			'👠',
			'👢',
			'👕',
			'👔',
			'👚',
			'👗',
			'🎽',
			'👖',
			'👘',
			'👙',
			'💼',
			'👜',
			'👝',
			'👛',
			'👓',
			'🎀',
			'🌂',
			'💄',
			'💛',
			'💙',
			'💜',
			'💚',
			'❤',
			'💔',
			'💗',
			'💓',
			'💕',
			'💖',
			'💞',
			'💘',
			'💌',
			'💋',
			'💍',
			'💎',
			'👤',
			'👥',
			'💬',
			'👣',
			'💭',
			'🐶',
			'🐺',
			'🐱',
			'🐭',
			'🐹',
			'🐰',
			'🐸',
			'🐯',
			'🐨',
			'🐻',
			'🐷',
			'🐽',
			'🐮',
			'🐗',
			'🐵',
			'🐒',
			'🐴',
			'🐑',
			'🐘',
			'🐼',
			'🐧',
			'🐦',
			'🐤',
			'🐥',
			'🐣',
			'🐔',
			'🐍',
			'🐢',
			'🐛',
			'🐝',
			'🐜',
			'🐞',
			'🐌',
			'🐙',
			'🐚',
			'🐠',
			'🐟',
			'🐬',
			'🐳',
			'🐋',
			'🐄',
			'🐏',
			'🐀',
			'🐃',
			'🐅',
			'🐇',
			'🐉',
			'🐎',
			'🐐',
			'🐓',
			'🐕',
			'🐖',
			'🐁',
			'🐂',
			'🐲',
			'🐡',
			'🐊',
			'🐫',
			'🐪',
			'🐆',
			'🐈',
			'🐩',
			'🐾',
			'💐',
			'🌸',
			'🌷',
			'🍀',
			'🌹',
			'🌻',
			'🌺',
			'🍁',
			'🍃',
			'🍂',
			'🌿',
			'🌾',
			'🍄',
			'🌵',
			'🌴',
			'🌲',
			'🌳',
			'🌰',
			'🌱',
			'🌼',
			'🌐',
			'🌞',
			'🌝',
			'🌚',
			'🌑',
			'🌒',
			'🌓',
			'🌔',
			'🌕',
			'🌖',
			'🌗',
			'🌘',
			'🌜',
			'🌛',
			'🌙',
			'🌍',
			'🌎',
			'🌏',
			'🌋',
			'🌌',
			'🌠',
			'⭐',
			'☀',
			'⛅',
			'☁',
			'⚡',
			'☔',
			'❄',
			'⛄',
			'🌀',
			'🌁',
			'🌈',
			'🌊',
			'🎍',
			'💝',
			'🎎',
			'🎒',
			'🎓',
			'🎏',
			'🎆',
			'🎇',
			'🎐',
			'🎑',
			'🎃',
			'👻',
			'🎅',
			'🎄',
			'🎁',
			'🎋',
			'🎉',
			'🎊',
			'🎈',
			'🎌',
			'🔮',
			'🎥',
			'📷',
			'📹',
			'📼',
			'💿',
			'📀',
			'💽',
			'💾',
			'💻',
			'📱',
			'☎',
			'📞',
			'📟',
			'📠',
			'📡',
			'📺',
			'📻',
			'🔊',
			'🔉',
			'🔈',
			'🔇',
			'🔔',
			'🔕',
			'📢',
			'📣',
			'⏳',
			'⌛',
			'⏰',
			'⌚',
			'🔓',
			'🔒',
			'🔏',
			'🔐',
			'🔑',
			'🔎',
			'💡',
			'🔦',
			'🔆',
			'🔅',
			'🔌',
			'🔋',
			'🔍',
			'🛁',
			'🛀',
			'🚿',
			'🚽',
			'🔧',
			'🔩',
			'🔨',
			'🚪',
			'🚬',
			'💣',
			'🔫',
			'🔪',
			'💊',
			'💉',
			'💰',
			'💴',
			'💵',
			'💷',
			'💶',
			'💳',
			'💸',
			'📲',
			'📧',
			'📥',
			'📤',
			'✉',
			'📩',
			'📨',
			'📯',
			'📫',
			'📪',
			'📬',
			'📭',
			'📮',
			'📦',
			'📝',
			'📄',
			'📃',
			'📑',
			'📊',
			'📈',
			'📉',
			'📜',
			'📋',
			'📅',
			'📆',
			'📇',
			'📁',
			'📂',
			'✂',
			'📌',
			'📎',
			'✒',
			'✏',
			'📏',
			'📐',
			'📕',
			'📗',
			'📘',
			'📙',
			'📓',
			'📔',
			'📒',
			'📚',
			'📖',
			'🔖',
			'📛',
			'🔬',
			'🔭',
			'📰',
			'🎨',
			'🎬',
			'🎤',
			'🎧',
			'🎼',
			'🎵',
			'🎶',
			'🎹',
			'🎻',
			'🎺',
			'🎷',
			'🎸',
			'👾',
			'🎮',
			'🃏',
			'🎴',
			'🀄',
			'🎲',
			'🎯',
			'🏈',
			'🏀',
			'⚽',
			'⚾',
			'🎾',
			'🎱',
			'🏉',
			'🎳',
			'⛳',
			'🚵',
			'🚴',
			'🏁',
			'🏇',
			'🏆',
			'🎿',
			'🏂',
			'🏊',
			'🏄',
			'🎣',
			'☕',
			'🍵',
			'🍶',
			'🍼',
			'🍺',
			'🍻',
			'🍸',
			'🍹',
			'🍷',
			'🍴',
			'🍕',
			'🍔',
			'🍟',
			'🍗',
			'🍖',
			'🍝',
			'🍛',
			'🍤',
			'🍱',
			'🍣',
			'🍥',
			'🍙',
			'🍘',
			'🍚',
			'🍜',
			'🍲',
			'🍢',
			'🍡',
			'🍳',
			'🍞',
			'🍩',
			'🍮',
			'🍦',
			'🍨',
			'🍧',
			'🎂',
			'🍰',
			'🍪',
			'🍫',
			'🍬',
			'🍭',
			'🍯',
			'🍎',
			'🍏',
			'🍊',
			'🍋',
			'🍒',
			'🍇',
			'🍉',
			'🍓',
			'🍑',
			'🍈',
			'🍌',
			'🍐',
			'🍍',
			'🍠',
			'🍆',
			'🍅',
			'🌽',
			'🏠',
			'🏡',
			'🏫',
			'🏢',
			'🏣',
			'🏥',
			'🏦',
			'🏪',
			'🏩',
			'🏨',
			'💒',
			'⛪',
			'🏬',
			'🏤',
			'🌇',
			'🌆',
			'🏯',
			'🏰',
			'⛺',
			'🏭',
			'🗼',
			'🗾',
			'🗻',
			'🌄',
			'🌅',
			'🌃',
			'🗽',
			'🌉',
			'🎠',
			'🎡',
			'⛲',
			'🎢',
			'🚢',
			'⛵',
			'🚤',
			'🚣',
			'⚓',
			'🚀',
			'✈',
			'💺',
			'🚁',
			'🚂',
			'🚊',
			'🚉',
			'🚞',
			'🚆',
			'🚄',
			'🚅',
			'🚈',
			'🚇',
			'🚝',
			'🚋',
			'🚃',
			'🚎',
			'🚌',
			'🚍',
			'🚙',
			'🚘',
			'🚗',
			'🚕',
			'🚖',
			'🚛',
			'🚚',
			'🚨',
			'🚓',
			'🚔',
			'🚒',
			'🚑',
			'🚐',
			'🚲',
			'🚡',
			'🚟',
			'🚠',
			'🚜',
			'💈',
			'🚏',
			'🎫',
			'🚦',
			'🚥',
			'⚠',
			'🚧',
			'🔰',
			'⛽',
			'🏮',
			'🎰',
			'♨',
			'🗿',
			'🎪',
			'🎭',
			'📍',
			'🚩',
			'⬆',
			'⬇',
			'⬅',
			'➡',
			'🔠',
			'🔡',
			'🔤',
			'↗',
			'↖',
			'↘',
			'↙',
			'↔',
			'↕',
			'🔄',
			'◀',
			'▶',
			'🔼',
			'🔽',
			'↩',
			'↪',
			'ℹ',
			'⏪',
			'⏩',
			'⏫',
			'⏬',
			'⤵',
			'⤴',
			'🆗',
			'🔀',
			'🔁',
			'🔂',
			'🆕',
			'🆙',
			'🆒',
			'🆓',
			'🆖',
			'📶',
			'🎦',
			'🈁',
			'🈯',
			'🈳',
			'🈵',
			'🈴',
			'🈲',
			'🉐',
			'🈹',
			'🈺',
			'🈶',
			'🈚',
			'🚻',
			'🚹',
			'🚺',
			'🚼',
			'🚾',
			'🚰',
			'🚮',
			'🅿',
			'♿',
			'🚭',
			'🈷',
			'🈸',
			'🈂',
			'Ⓜ',
			'🛂',
			'🛄',
			'🛅',
			'🛃',
			'🉑',
			'㊙',
			'㊗',
			'🆑',
			'🆘',
			'🆔',
			'🚫',
			'🔞',
			'📵',
			'🚯',
			'🚱',
			'🚳',
			'🚷',
			'🚸',
			'⛔',
			'✳',
			'❇',
			'❎',
			'✅',
			'✴',
			'💟',
			'🆚',
			'📳',
			'📴',
			'🅰',
			'🅱',
			'🆎',
			'🅾',
			'💠',
			'➿',
			'♻',
			'♈',
			'♉',
			'♊',
			'♋',
			'♌',
			'♍',
			'♎',
			'♏',
			'♐',
			'♑',
			'♒',
			'♓',
			'⛎',
			'🔯',
			'🏧',
			'💹',
			'💲',
			'💱',
			'©',
			'®',
			'™',
			'〽',
			'〰',
			'🔝',
			'🔚',
			'🔙',
			'🔛',
			'🔜',
			'❌',
			'⭕',
			'❗',
			'❓',
			'❕',
			'❔',
			'🔃',
			'🕛',
			'🕧',
			'🕐',
			'🕜',
			'🕑',
			'🕝',
			'🕒',
			'🕞',
			'🕓',
			'🕟',
			'🕔',
			'🕠',
			'🕕',
			'🕖',
			'🕗',
			'🕘',
			'🕙',
			'🕚',
			'🕡',
			'🕢',
			'🕣',
			'🕤',
			'🕥',
			'🕦',
			'✖',
			'➕',
			'➖',
			'➗',
			'♠',
			'♥',
			'♣',
			'♦',
			'💮',
			'💯',
			'✔',
			'☑',
			'🔘',
			'🔗',
			'➰',
			'🔱',
			'🔲',
			'🔳',
			'◼',
			'◻',
			'◾',
			'◽',
			'▪',
			'▫',
			'🔺',
			'⬜',
			'⬛',
			'⚫',
			'⚪',
			'🔴',
			'🔵',
			'🔻',
			'🔶',
			'🔷',
			'🔸',
			'🔹',
		];

		this.init();
	}
	init() {
		this.setPreload();
		this.setTitle();
		this.BackGround();
		this.setMenuActive();
		this.setPrologue()
	}
	setTitle() {
		let domTitle = document.title;
		setInterval(() => {
			document.title = domTitle + ' ' + this.setEmojisRandom();
		}, 3000);
	}
	setEmojisRandom() {
		let emojirandom = this.emojis[Math.floor(Math.random() * this.emojis.length)];
		return emojirandom;
	}
	setPreload() {
		let preload = document.querySelector('.preload');
		window.addEventListener('DOMContentLoaded', () => {
			preload.classList.add('loaded')
		})
	}
	setPrologue() {
		let prologueBtn = document.querySelector('.question-btn');
		let prologue = document.querySelector('.prologue');
		prologueBtn.addEventListener('click', (e) => {
			prologueBtn.classList.toggle("btn-active")
			prologue.classList.toggle("log-active");
		});
	}
	setMenuActive() {
		let hamburger = document.querySelector('.navi-hamburger');
		let navList = document.querySelector('.navi-lists');
		let navList_count = navList.childElementCount;

		hamburger.addEventListener('click', (e) => {
			hamburger.classList.toggle("active")
			navList.classList.toggle("nav-active");

			for (let i = 0; i < navList_count; i++) {
				console.log(navList_count.children[i]);
				// gsap.from(navList_count.children[i], {
				// 	x: 10,
				// 	autoAlpha: 0,
				// 	duration: 1,
				// 	ease: "power4.out",
				// 	stagger: 0.02, // 0.02秒ごとに出現
				// });
			}
		});
	}

	BackGround() {
		document.addEventListener('DOMContentLoaded', init, false)

		let APP

		function init() {
			APP = new App()

			events()

			loop()
		}

		function loop() {
			APP.render()

			requestAnimationFrame(loop)
		}

		function events() {
			document.addEventListener('mousemove', APP.mousemoveHandler, false)
			document.addEventListener('mouseleave', APP.mouseleaveHandler, false)

			window.addEventListener('resize', APP.resize, false)
		}

	}
}


class App {
	constructor() {
		this.canvas = document.getElementById('playground')
		this.context = this.canvas.getContext('2d')

		this.canvas.width = this.width = window.innerWidth
		this.canvas.height = this.height = window.innerHeight

		this.setupDots()

		this.resize = this.resize.bind(this)
		this.mousemoveHandler = this.mousemoveHandler.bind(this)
		this.mouseleaveHandler = this.mouseleaveHandler.bind(this)
	}

	setupDots() {
		this.dots = []
		this.scl = 60
		this.cols = this.width / this.scl
		this.rows = this.height / this.scl

		let id = 0
		for (let x = 0; x < this.cols; x += 1) {
			for (let y = 0; y < this.rows; y += 1) {
				this.dots.push(new Dot(id, x * this.scl, y * this.scl, this.context, this.scl))
				id += 1
			}
		}
	}

	resize() {
		this.canvas.width = this.width = window.innerWidth
		this.canvas.height = this.height = window.innerHeight

		this.setupDots()
	}

	mousemoveHandler(event) {
		this.dots.forEach(d => {
			d.mousemove(event)
		})
	}

	mouseleaveHandler() {
		this.dots.forEach(d => {
			d.isHover = false
		})
	}

	render() {
		this.context.clearRect(0, 0, this.width, this.height)

		this.dots.forEach(d => {
			d.render()
		})
	}
}

class Dot {
	constructor(id, x, y, context, scl) {
		this.id = id
		this.x = x
		this.y = y
		this.new = {
			x: x,
			y: y
		}
		this.radius = 3

		this.context = context
		this.scl = scl
		this.isHover = false
		this.isANimated = false
	}

	mousemove(event) {
		const x = event.clientX
		const y = event.clientY

		this.isHover = ((Math.abs(this.x - x) < (this.scl / 4 * 3)) && (Math.abs(this.y - y) < (this.scl / 4 * 3))) ? true : false

		if (this.isHover) {
			gsap.to(
				this.new,
				0.4, {
					x: x,
					y: y + 100
				}
			)
		} else {
			gsap.to(
				this.new,
				0.4, {
					x: this.x,
					y: this.y
				}
			)
		}
	}

	render() {
		this.context.beginPath()
		this.context.arc(this.new.x, this.new.y, this.radius, 0, 2 * Math.PI, false)

		this.context.fillStyle = 'rgba(255,255,255,1)'
		this.context.globalAlpha = (this.isHover) ? 1 : 0.8
		this.context.fill()
	}
}
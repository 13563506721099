import * as THREE from 'three';
import {
	OrbitControls
} from 'three/examples/jsm/controls/OrbitControls.js';
import Stats from 'stats.js';
import vertexShader from '../shaders/grain.vert';
import fragmentShader from '../shaders/grain.frag';

import Models from './models';

import {
	gsap,
	Power4
} from 'gsap';

import * as dat from 'lil-gui';

export default class Scene {
	// サイズ
	sizes = {
		width: window.innerWidth,
		height: window.innerHeight,
	};
	constructor(sceneEl) {
		this.canvas = sceneEl;
		this.init();
	}
	init() {
		this.setScene();
		this.setRender();
		this.setCamera();

		// this.setControls();
		// this.setStats();
		this.setFog();
		this.setRaycaster();
		this.setContainer();
		this.setMaterial();
		this.setLight();
		// this.setGUI();
		this.setGLTF();
		this.events();
	}

	//　Scene
	setScene() {
		this.params = {
			color: '#60C7FB',
		};
		this.scene = new THREE.Scene();
		// this.scene.background = new THREE.Color(this.params.color);
	}

	setRender() {
		this.renderer = new THREE.WebGLRenderer({
			canvas: this.canvas,
			antialias: true,
			alpha: true
		});

		this.renderer.setSize(this.sizes.width, this.sizes.height);
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		this.renderer.physicallyCorrectLights = true;
		this.renderer.toneMapping = THREE.ReinhardToneMapping;
		this.renderer.toneMappingExposure = 2.3;
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
	}

	setCamera() {
		this.camera = new THREE.PerspectiveCamera(20, this.sizes.width / this.sizes.height, 0.1, 5000);

		this.camera.position.set(0, 35, 1100);
		this.scene.add(this.camera);
	}

	setStats() {
		let stats = new Stats();
		stats.showPanel(1);
		document.body.appendChild(stats.dom);

		function animate() {
			stats.begin();

			// monitored code goes here

			stats.end();

			requestAnimationFrame(animate);
		}

		requestAnimationFrame(animate);
	}

	setControls() {
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.enableDamping = true;
		this.controls.autoRotate = true;
		this.controls.enableZoom = false;
		this.controls.enablePan = false;
		this.controls.dampingFactor = 0.05;
		this.controls.maxDistance = 1000;
		this.controls.minDistance = 30;
		this.controls.touches = {
			ONE: THREE.TOUCH.ROTATE,
			TWO: THREE.TOUCH.DOLLY_PAN,
		};
	}

	setContainer() {
		this.container = new THREE.Object3D();
		this.scene.add(this.container);
	}

	setMaterial() {
		this.uniforms = {
			u_time: {
				value: 0.0
			},
			u_mouse: {
				value: {
					x: 0.0,
					y: 0.0
				}
			},
			u_resolution: {
				value: {
					x: 0,
					y: 0
				}
			},
			u_radius: {
				value: 20.0
			},
		};

		this.customMaterial = new THREE.ShaderMaterial({
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			uniforms: this.uniforms,
			wireframe: true,
		});

		this.grainMaterial = this.customMaterial;
	}

	setRaycaster() {
		this.mouse = new THREE.Vector2();
		this.raycaster = new THREE.Raycaster();
		this.raycaster.params.Points.threshold = 0.1;

		console.log(this.raycaster);

		// let model = this.models.group.children[0]
		// gsap.from(model.children[0].scale, {
		// 	x: 10,
		// 	y: 10,
		// 	z: 10,
		// 	duration: 0.8,
		// 	ease: Power4.out
		// });
	}

	setLight() {
		this.ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
		this.spotLight = new THREE.SpotLight(0xffffff, 10.0);
		this.directionalLight01 = new THREE.DirectionalLight(0xffffff, 1);
		this.directionalLight02 = new THREE.DirectionalLight(0xffffff, 1);
		this.directionalLight01.castShadow = true;
		this.directionalLight02.castShadow = true;
		this.directionalLight01.shadow.radius = 8;
		this.directionalLight02.shadow.radius = 8;

		this.directionalLight01.shadow.normalBias = 0.05;
		this.directionalLight02.shadow.normalBias = 0.05;
		console.log(this.directionalLight02.shadow);

		this.directionalLight01.shadow.mapSize.width = 2048;
		this.directionalLight01.shadow.mapSize.height = 2048;

		this.directionalLight01.position.set(30, 30, 30);
		this.directionalLight02.position.set(30, 30, 30);
		this.scene.add(this.ambientLight, this.directionalLight01, this.directionalLight02, this.spotLight);

		const lightHelper = new THREE.DirectionalLightHelper(this.directionalLight01);
		// this.scene.add(lightHelper);
	}

	setFog() {
		this.scene.fog = new THREE.Fog(0xffffff, 50, 2000);
		console.log(this.scene.fog);
	}

	setGUI() {
		const gui = new dat.GUI();
		gui.add(this.uniforms.u_radius, 'value', 0, 100);

		// let changecolor = this.scene;
		// gui.addColor(this.params, 'color').onChange(function (value) {
		// 	changecolor.background = new THREE.Color(value);
		// });

		let LightFolder01 = gui.addFolder('Light01');
		LightFolder01.add(this.directionalLight01, 'intensity', 0, 5);
		LightFolder01.add(this.directionalLight01.position, 'y', -1000, 1000);
		LightFolder01.add(this.directionalLight01.position, 'z', -1000, 1000);
		LightFolder01.open();

		let LightFolder02 = gui.addFolder('Light02');
		LightFolder02.add(this.directionalLight02, 'intensity', 0, 5);
		LightFolder02.add(this.directionalLight02.position, 'x', -1000, 1000);
		LightFolder02.add(this.directionalLight02.position, 'y', -1000, 1000);
		LightFolder02.add(this.directionalLight02.position, 'z', -1000, 1000);
		LightFolder02.open();
	}

	setGLTF() {
		this.models = new Models(this.scene, 45);
	}

	events() {
		window.addEventListener('resize', this.handleResize, {
			passive: true
		});
		window.addEventListener('mousemove', this.handleMousemove, {
			passive: true
		});
		window.addEventListener('wheel', this.handleWheel, {
			passive: true
		});
		window.addEventListener('scroll', this.handleScroll, {
			passive: true
		});
		window.addEventListener('click', this.handleClick, {
			passive: true
		});
		this.draw();
	}

	draw = () => {
		const clock = new THREE.Clock();
		const elapsedTime = clock.getElapsedTime();

		this.models.group.rotation.y += 0.001;

		this.setScroll();

		this.models.update();

		// this.controls.update();

		this.renderer.render(this.scene, this.camera);

		// this.uniforms.u_time.value += clock.getDelta();
		requestAnimationFrame(this.draw);
	};

	handleClick = (e) => {
		this.raycaster.setFromCamera(this.mouse, this.camera);
		this.models.group.children.forEach((model) => {
			const intersects = this.raycaster.intersectObject(model);
			if (intersects.length > 0) {
				console.log(this);
			}
		});
	};

	setScroll() {
		this.speed = 0;

		if (this.speed > 0) {
			this.models.group.rotation.y -= 0.001;
		}
	}

	handleResize = () => {
		this.sizes.width = window.innerWidth;
		this.sizes.height = window.innerHeight;

		this.camera.aspect = this.sizes.width / this.sizes.height;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(this.sizes.width, this.sizes.height);
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		this.uniforms.u_resolution.value.x = window.innerWidth;
		this.uniforms.u_resolution.value.y = window.innerHeight;
	};

	handleMousemove = (evt) => {
		this.uniforms.u_mouse.value.x = evt.touches ? evt.touches[0].clientX : evt.clientX;
		this.uniforms.u_mouse.value.y = evt.touches ? evt.touches[0].clientY : evt.clientY;

		// Raycaster for mouse
		this.mouse.x = (evt.clientX / this.sizes.width) * 2 - 1;
		this.mouse.y = -(evt.clientY / this.sizes.height) * 2 + 1;
	};

	handleWheel = (e) => {
		this.speed += e.deltaY * 0.0003 * 0.5;

		// console.log(e.deltaY);

		// this.models
		// model.children[0].scale.set(2, 2, 2)
		// let model = this.models.group.children[0]
		// gsap.from(model.children[0].scale, {
		// 	x: 10,
		// 	y: 10,
		// 	z: 10,
		// 	duration: 0.8,
		// 	ease: Power4.out
		// });


		this.models.group.rotation.y += this.speed * 0.1;
	};

	handleScroll = (e) => {
		const WindowScrollY = window.pageYOffset;
		const scrollModel = this.models.group.children;

		scrollModel.forEach((e) => {
			gsap.from(e.position, {
				y: WindowScrollY * 0.05,
				duration: 0.4,
				ease: Power4.out
			});
		})


	};
}
import * as THREE from 'three';

import emojimodel_01 from '../../models/emoji_model/Emoji01.glb';
import emojimodel_02 from '../../models/emoji_model/Emoji02.glb';
import emojimodel_03 from '../../models/emoji_model/Emoji03.glb';
import emojimodel_04 from '../../models/emoji_model/Emoji04.glb';
import emojimodel_05 from '../../models/emoji_model/Emoji05.glb';
import emojimodel_06 from '../../models/emoji_model/Emoji06.glb';
import emojimodel_07 from '../../models/emoji_model/Emoji07.glb';
import emojimodel_08 from '../../models/emoji_model/Emoji08.glb';
import emojimodel_09 from '../../models/emoji_model/Emoji09.glb';
import emojimodel_10 from '../../models/emoji_model/Emoji10.glb';
import emojimodel_11 from '../../models/emoji_model/Emoji11.glb';
import emojimodel_12 from '../../models/emoji_model/Emoji12.glb';
import emojimodel_13 from '../../models/emoji_model/Emoji13.glb';
import emojimodel_14 from '../../models/emoji_model/Emoji14.glb';
import emojimodel_15 from '../../models/emoji_model/Emoji15.glb';
import emojimodel_16 from '../../models/emoji_model/Emoji16.glb';
import emojimodel_17 from '../../models/emoji_model/Emoji17.glb';
import emojimodel_18 from '../../models/emoji_model/Emoji18.glb';
import emojimodel_19 from '../../models/emoji_model/Emoji19.glb';
import emojimodel_20 from '../../models/emoji_model/Emoji20.glb';
import emojimodel_21 from '../../models/emoji_model/Emoji21.glb';
import emojimodel_22 from '../../models/emoji_model/Emoji22.glb';
import emojimodel_23 from '../../models/emoji_model/Emoji23.glb';
import emojimodel_24 from '../../models/emoji_model/Emoji24.glb';
import emojimodel_25 from '../../models/emoji_model/Emoji25.glb';
import emojimodel_26 from '../../models/emoji_model/Emoji26.glb';
import emojimodel_27 from '../../models/emoji_model/Emoji27.glb';
import emojimodel_28 from '../../models/emoji_model/Emoji28.glb';
import emojimodel_29 from '../../models/emoji_model/Emoji29.glb';
import emojimodel_30 from '../../models/emoji_model/Emoji30.glb';
import emojimodel_31 from '../../models/emoji_model/Emoji31.glb';
import emojimodel_32 from '../../models/emoji_model/Emoji32.glb';
import emojimodel_33 from '../../models/emoji_model/Emoji33.glb';
import emojimodel_34 from '../../models/emoji_model/Emoji34.glb';
import emojimodel_35 from '../../models/emoji_model/Emoji35.glb';
import emojimodel_36 from '../../models/emoji_model/Emoji36.glb';
import emojimodel_37 from '../../models/emoji_model/Emoji37.glb';
import emojimodel_38 from '../../models/emoji_model/Emoji38.glb';
import emojimodel_39 from '../../models/emoji_model/Emoji39.glb';
import emojimodel_40 from '../../models/emoji_model/Emoji40.glb';
import emojimodel_41 from '../../models/emoji_model/Emoji41.glb';
import emojimodel_42 from '../../models/emoji_model/Emoji42.glb';
import emojimodel_43 from '../../models/emoji_model/Emoji43.glb';
import emojimodel_44 from '../../models/emoji_model/Emoji44.glb';
import emojimodel_45 from '../../models/emoji_model/Emoji45.glb';
import emojimodel_46 from '../../models/emoji_model/Emoji46.glb';
import emojimodel_47 from '../../models/emoji_model/Emoji47.glb';
import emojimodel_48 from '../../models/emoji_model/Emoji48.glb';
import emojimodel_50 from '../../models/emoji_model/Emoji50.glb';
import emojimodel_51 from '../../models/emoji_model/Emoji51.glb';
import emojimodel_52 from '../../models/emoji_model/Emoji52.glb';
import emojimodel_53 from '../../models/emoji_model/Emoji53.glb';
import emojimodel_54 from '../../models/emoji_model/Emoji54.glb';
import emojimodel_55 from '../../models/emoji_model/Emoji55.glb';
import emojimodel_56 from '../../models/emoji_model/Emoji56.glb';
import emojimodel_57 from '../../models/emoji_model/Emoji57.glb';
import emojimodel_58 from '../../models/emoji_model/Emoji58.glb';
import emojimodel_59 from '../../models/emoji_model/Emoji59.glb';
import emojimodel_60 from '../../models/emoji_model/Emoji60.glb';

import {
	GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader';
import {
	DRACOLoader
} from 'three/examples/jsm/loaders/DRACOLoader';

import {
	gsap,
	Power4
} from 'gsap';

import fragtShader from '../shaders/grain.frag';

const glbModels = [
	emojimodel_01,
	emojimodel_02,
	emojimodel_03,
	emojimodel_04,
	emojimodel_05,
	emojimodel_06,
	emojimodel_07,
	emojimodel_08,
	emojimodel_09,
	emojimodel_10,
	emojimodel_11,
	emojimodel_12,
	emojimodel_13,
	emojimodel_14,
	emojimodel_15,
	emojimodel_16,
	emojimodel_17,
	emojimodel_18,
	emojimodel_19,
	emojimodel_20,
	emojimodel_21,
	emojimodel_22,
	emojimodel_24,
	emojimodel_25,
	emojimodel_26,
	emojimodel_27,
	emojimodel_28,
	emojimodel_29,
	emojimodel_30,
	emojimodel_31,
	emojimodel_32,
	emojimodel_33,
	emojimodel_34,
	emojimodel_35,
	emojimodel_36,
	emojimodel_37,
	emojimodel_38,
	emojimodel_39,
	emojimodel_40,
	emojimodel_41,
	emojimodel_42,
	emojimodel_43,
	emojimodel_44,
	emojimodel_45,
	emojimodel_46,
	emojimodel_47,
	emojimodel_48,
	// emojimodel_50,
	// emojimodel_51,
	// emojimodel_52,
	// emojimodel_53,
	// emojimodel_54,
	// emojimodel_55,
	// emojimodel_56,
	// emojimodel_57,
	// emojimodel_58,
	// emojimodel_59,
	// emojimodel_60
];

export default class Models {
	constructor(el, index) {
		this.el = el;
		this.index = index;

		this.setModel();
	}
	setModel() {
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath('/draco/');

		const loader = new GLTFLoader();
		loader.setDRACOLoader(dracoLoader);

		this.group = new THREE.Group();
		this.el.add(this.group);

		for (let i = 0; i < this.index; i++) {
			let example = new THREE.Object3D();

			loader.load(glbModels[i], (gltf) => {
				example = gltf.scene;
				const radian = (i / this.index) * Math.PI * 2;

				example.traverse((object) => {
					if (object.isMesh) {
						// object.material.color.r = 1;
						object.material.program = {
							fragmentShader: fragtShader,
						};
					}
				});


				const circlerotation = -((Math.PI * 2) / this.index) * i;
				example.position.set(500 * Math.cos(radian), 8, 500 * Math.sin(radian));
				example.rotation.set(0, circlerotation - 11, 0);
				example.scale.set(20, 20, 20);
				example.castShadow = true;

				gsap.from(example.scale, {
					x: 0,
					y: 0,
					z: 0,
					duration: 0.8,
					ease: Power4.out
				});

				gsap.from(example.position, {
					x: 0,
					y: 0,
					z: 0,
					duration: 0.8,
					ease: Power4.out
				});

				this.el.add(example);
				this.group.add(example);
			});
		}
	}

	update() {
		this.group.children.map((element) => {
			// element.rotation.y += 0.01;
		});
	}
}
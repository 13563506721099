import emojiJson from '../../../json/emoji.json';
import '../../../style/emoji-list.scss';

/**
 * <ul class='emoji_lists'>
 * 		<li class='emoji_lists--item'>{unicode}</li>
 *　　</ul>;
 **/

const emojiList = document.querySelector('.emoji-lists');

const ul = document.createElement('ul');
ul.classList.add('emoji_lists');

const emoji__content = document.createElement('div');
emoji__content.classList.add('emoji__content');
const div_emoji = document.createElement('div');
div_emoji.classList.add('div_emoji');
const div_name = document.createElement('div');
div_name.classList.add('div_name');
const div_content = document.createElement('div');
div_content.classList.add('div_content');

emojiJson.map((element) => {
    const unicode = element.unicode;
    let emoji = String.fromCodePoint('0x' + unicode);
    const li = document.createElement('li');
    li.classList.add('emoji_lists--item');
    li.innerHTML = emoji;
    ul.appendChild(li);

    /**
     * <div class='emoji__content'>
     * 		<div class="div_emoji">😃</div>
     * 		<div class="div_name">大きい目の笑顔</div>
     * 		<div class="div_content">grinning face with big eyes</div>
     *　　</div>;
     **/

    li.addEventListener('mouseover', (e) => {
        div_emoji.innerHTML = emoji;
        div_name.innerHTML = element.name;
        div_content.innerHTML = element.en_name;

        emoji__content.style.scale = '1';
    });
    li.addEventListener('mouseleave', (e) => {
        div_emoji.innerHTML = emoji;
        div_name.innerHTML = element.name;
        div_content.innerHTML = element.en_name;

        emoji__content.style.scale = '0';
    });
});

window.addEventListener('mousemove', (e) => {
    emoji__content.style.transition = 'all 0.8s ease-out';
    emoji__content.style.position = 'fixed';
    emoji__content.style.top = `${e.clientY + 20}px`;
    emoji__content.style.left = `${e.clientX + 20}px`;
});

emoji__content.appendChild(div_emoji);
emoji__content.appendChild(div_name);
emoji__content.appendChild(div_content);

if (emojiList) {
    emojiList.appendChild(emoji__content);
    emojiList.appendChild(ul);
}